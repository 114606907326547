import React from "react";
import { ButtonProps } from "./types";

const Button = ({ children, label }: ButtonProps) => {
  return (
    <React.Fragment>
      <label>{label}</label>
      <button>{children}</button>
    </React.Fragment>
  );
};

export default Button;
